import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCadreApi from '@/hooks/cadre/useCadreApi';

const { position } = useCadreApi();

export default defineStore('cadrePosition', () => {
  const { state, fetch, fetched } = useRemoteResource('cadre.position', position.fetch);

  return {
    state,
    fetch,
    fetched,
  };
});
