import { ref } from 'vue';
import useBaseApi from '../useBaseApi';

/**
 * This file is a subject to change in future
 * releases, along with other Base hooks
 *
 * TODO: fix the structure and refactor
 */

const report = ref({});
const currentMeterStatus = ref(0);

export default function useMachineMthCard() {
  const updateChart = (machineId, force = false) => {
    if (report.value?.machineId === machineId && !force) return;
    report.value.machineId = machineId;

    useBaseApi()
      .fetchMthReport(machineId)
      .then(({ data }) => {
        report.value = {
          ...data.data,
          machineId,
        };
        currentMeterStatus.value = Number(data.data.posterior_meter ?? 0);
      });
  };

  return {
    report,
    currentMeterStatus,

    updateChart,
  };
}
