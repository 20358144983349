var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cadre-position-picker-option"
  }, [_vm.group ? _c('div', {
    staticClass: "cadre-position-picker-option__group-name"
  }, [_vm._v(" " + _vm._s(_vm.group.name) + " ")]) : _vm._e(), _c('div', {
    staticClass: "cadre-position-picker-option__position-name"
  }, [_vm._v(" " + _vm._s(_vm.position.name) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }