<template>
  <div class="investments-report-form-details">
    <table>
      <tr>
        <td>inwestycja</td>
        <td>
          <investments-investment-label
            :investment="investment"
            :id="investment.id"
            target="_blank"
          />
        </td>
      </tr>
      <tr>
        <td>data raportu</td>
        <td>{{ formatDate.baseReport(reportDate) }}</td>
      </tr>
      <tr>
        <td>raportujący</td>
        <user-label withAvatar />
      </tr>
    </table>
  </div>
</template>

<script>
import UserLabel from '@/components/ui/UserLabel.vue';
import formatDate from '@/libs/date-formatter';
import InvestmentsInvestmentLabel from '../../InvestmentsInvestmentLabel.vue';

export default {
  name: 'InvestmentsReportFormDetails',
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
    reportDate: {
      type: String,
      default: '-',
    },
  },
  setup() {
    return {
      formatDate,
    };
  },
  components: {
    UserLabel,
    InvestmentsInvestmentLabel,
  },
};
</script>

<style lang="sass" scoped>
.investments-report-form-details
  padding: 0 0 30px 0

table
  tr
    td
      padding: 10px 0

      &:first-child
        opacity: 0.6
        padding-right: 20px
</style>
