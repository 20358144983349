<template>
  <div class="form-steps-indicator">
    <div
      v-for="(step, index) in steps"
      :key="`step-${index}`"
      class="step"
      :class="{ ...computedClasses, 'step--active': index === currentStep }"
      @click="() => stepClicked(index)"
      >
      <div class="step-count">{{ index + 1 }}</div>
      <div class="step-name">
        {{ step }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormStepsIndicator',
  emits: ['stepChange'],
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    interactive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const steps = [
      'Zespół i przeroby',
      'Stan licznika',
      'Paliwo',
      'Koszty dodatkowe',
    ];

    const computedClasses = {
      'step--interactive': props.interactive,
    };

    const stepClicked = (index) => {
      if (!props.interactive) return;
      emit('stepChange', index);
    };

    return { steps, computedClasses, stepClicked };
  },
};
</script>

<style lang="sass" scoped>
.form-steps-indicator
  display: flex
  justify-content: space-around
  position: relative
  margin: 20px 0

  &:before
    content: ''
    display: block
    position: absolute
    width: 100%
    height: 2px
    left: 0
    top: 17px

    $gradient1: rgba(255,255,255,0) 0%
    $gradient2: rgba(128,128,128,0.1) 40%
    $gradient3: rgba(128,128,128,0.1) 60%
    $gradient4: rgba(255,255,255,0) 100%

    background: linear-gradient(90deg, $gradient1, $gradient2, $gradient3, $gradient4)
    z-index: 1

  .step
    display: flex
    flex-direction: column
    align-items: center
    flex: 1 1 0px

    &--active
      color: var(--vs-primary)
      .step-count
        border-color: var(--vs-primary)
        font-weight: 500

    &--interactive
      cursor: pointer

    &-count
      width: 35px
      height: 35px
      border: 2px solid rgba(128, 128, 128, 0.3)
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      background: var(--vs-card-bg)
      z-index: 100
      posiiton: relative

    &-name
      margin-top: 10px
</style>
