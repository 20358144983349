var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalCreateFueling
    },
    on: {
      "ok": _vm.createRefueling,
      "hide": _vm.resetFields
    }
  }, [_c('investments-fueling-form', {
    attrs: {
      "value": _vm.fields,
      "feedback": _vm.feedback
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }