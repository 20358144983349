<template>
  <div class="machine-mth-report-modal">
    <b-modal
      id="modal-base-machines-mth-report-edit"
      title="Edytuj raport"
      cancel-variant="outline-secondary"
      :cancel-title="$t('base.machines.modal.reportMth.button.cancel')"
      :ok-title="$t('base.machines.modal.reportMth.button.save')"
      size="lg"
      v-model="mthReportModalEditVisible"
      @ok.prevent="updateReport"
      no-close-on-backdrop
    >
      <vs-loader full
        text="Tworzenie raportu..."
        v-if="processing"
      />

      <resource-report-form
        v-model="form"
        :feedback="feedback"
        :refuel="form.has_fuel"
        :machineWhitelist="machineWhitelist"
        :employeeWhitelist="employeeWhitelist"
      />

      <template #modal-footer="{ ok }">
        <vs-modal-footer
          :is-loading="processing"

          :text-cancel="$t('base.machines.modal.reportMth.button.cancel')"
          :text-ok="$t('base.machines.modal.reportMth.button.save')"

          @ok="ok"
          @cancel="mthReportModalEditVisible = false"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>

import useMachineReports from '@/hooks/base/machine/useMachineReports';
import { watch } from 'vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import refuelingTable from '@/hooks/tables/base/refuelingTable';
import showToast from '@/libs/toasts';
import { UpdateMachineReportError, UpdateMachineReportSuccess } from '@/libs/toasts/feedback/module/base';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import ResourceReportForm from '../../resource/forms/ResourceReportForm.vue';

export default {
  name: 'MachinesMthReportModalEdit',
  props: {
    type: {
      type: String,
    },
    table: {
      type: Object,
    },
    forEquipment: {
      type: Boolean,
      defualt: false,
    },
    machineWhitelist: {
      type: Array,
    },
    employeeWhitelist: {
      type: Array,
    },
    noTableReload: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      mthReportModalEditVisible,
      form,
      editedReport,
      fillEditingReport,
      feedback,
      processing,
      validations,
      resetForm,
      errors,
    } = useMachineReports();

    watch(() => editedReport.value, () => fillEditingReport(props.forEquipment));

    const updateReport = async () => {
      const valid = await validations.validateAllFields();

      if (!valid.every((field) => field)) return;
      processing.value = true;

      const report = { ...form.value, base_resource_id: form.value.object_id };
      delete report.machine;
      delete report.object_id;
      delete report.time;

      useBaseApi()
        .updateMachineReport(report)
        .then(() => {
          mthReportModalEditVisible.value = false;
          editedReport.value = null;
          processing.value = false;

          if (!props.noTableReload) {
            refuelingTable.value.fetchData();
          }

          showToast(UpdateMachineReportSuccess, { name: '' });
          emit('success', report);
          resetForm();
        })
        .catch(({ response }) => {
          errors.serverSide.value = response?.data?.errors;
          processing.value = false;

          showToast(UpdateMachineReportError);
        });
    };

    return {
      mthReportModalEditVisible,
      updateReport,
      form,
      feedback,
      processing,
    };
  },
  components: { ResourceReportForm, VsLoader, VsModalFooter },
};

</script>
