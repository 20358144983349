var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-report-form-fuel"
  }, [_c('investments-report-form-details', {
    attrs: {
      "investment": _vm.investment,
      "reportDate": _vm.reportDate
    }
  }), _vm.isLoading && !_vm.fuelReports.length ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Wczytywanie raportów"
    }
  }) : _vm._e(), _vm.fuelReports.length || _vm.disableAdd ? _c('div', {
    staticClass: "report-table-wrapper"
  }, [_c('vs-form-header', {
    attrs: {
      "withButton": !_vm.disableAdd,
      "text": `Raporty ${_vm.reportType === 'fuel' ? 'tankowań' : 'motogodzin'}
        z dnia ${_vm.formatDate.training(_vm.reportDate)}`,
      "buttonText": "+ Dodaj raport"
    },
    on: {
      "action": _vm.openReportModal
    }
  }), !_vm.disableAdd || _vm.fuelReports.length ? _c('vs-table', {
    staticClass: "owned-list",
    attrs: {
      "showActionButton": !_vm.disableEdit,
      "columns": _vm.reportColumns,
      "rows": _vm.fuelReports,
      "actionItems": _vm.reportActionButtons,
      "show-search": false,
      "show-pagination": false,
      "isLoading": _vm.isLoading,
      "sortOptions": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var _row$resource, _row$resource$categor;
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'report_date' ? _c('div', [_vm._v(" " + _vm._s(_vm.formatDate.baseReport(row.report_date)) + " ")]) : column.field === 'reporter_id' ? _c('div', [_c('user-label', {
          attrs: {
            "withAvatar": "",
            "id": row.reporter_id
          }
        })], 1) : column.field === 'name' ? _c('div', [_c('base-resource-label', {
          attrs: {
            "id": row.resource.vehicle_id,
            "resource": row.resource,
            "target": "_blank"
          }
        })], 1) : column.field === 'amount_fuel' ? _c('div', [_vm._v(" " + _vm._s(Number(row.amount_fuel || 0).toLocaleString()) + " l ")]) : column.field === 'meter_status' ? _c('div', [_vm._v(" " + _vm._s(Number(row.meter_status || 0).toLocaleString()) + " " + _vm._s(_vm.unitStore.getShortcode((_row$resource = row.resource) === null || _row$resource === void 0 ? void 0 : (_row$resource$categor = _row$resource.category) === null || _row$resource$categor === void 0 ? void 0 : _row$resource$categor.unit_id)) + " ")]) : _vm._e()];
      }
    }], null, false, 2678943624)
  }) : !_vm.isLoading ? _c('div', {
    staticClass: "no-reports-info"
  }, [_vm._v(" Inwestycja nie posiada żadnych raportów " + _vm._s(_vm.reportType === 'fuel' ? 'tankowania' : 'przerobowych') + " w danym okresie ")]) : _vm._e()], 1) : _vm._e(), _vm.resourcesWithoutReports.length && !_vm.disableAdd ? _c('div', {
    staticClass: "resource-table-wrapper"
  }, [_c('vs-form-header', {
    staticClass: "without-reports-header",
    attrs: {
      "withButton": "",
      "text": "Maszyny i sprzęty bez raportów",
      "buttonText": "+ Dodaj raport"
    },
    on: {
      "action": _vm.openReportModal
    }
  }), _c('vs-table', {
    staticClass: "owned-list",
    attrs: {
      "columns": _vm.withoutReportColumns,
      "rows": _vm.resourcesWithoutReports,
      "show-search": false,
      "show-pagination": false,
      "showActionButton": false,
      "sortOptions": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref2) {
        var column = _ref2.column,
          row = _ref2.row;
        return [column.field === 'add' ? _c('div', {
          staticClass: "add-report-wrapper"
        }, [_c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": function () {
              return _vm.openReportModalForResource(row.resource_id);
            }
          }
        }, [_vm._v(" Dodaj raport ")])], 1) : column.field === 'name' ? _c('div', [_c('base-resource-label', {
          attrs: {
            "id": row.vehicle_id,
            "resource": row,
            "target": "_blank"
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 2489325813)
  })], 1) : _vm._e(), _c('machines-mth-report-modal-edit', {
    attrs: {
      "noTableReload": "",
      "machineWhitelist": _vm.investmentResources.map(function (r) {
        return r.vehicle_id;
      }),
      "employeeWhitelist": _vm.investmentEmployeeIds
    },
    on: {
      "success": _vm.fetchReports
    }
  }), _c('machines-mth-report-modal', {
    on: {
      "ok": _vm.fetchReports
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }