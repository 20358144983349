<template>
  <div class="vs-employee-multiselect">
    <div
      v-for="employee in employees"
      :key="employee.id"
      class="employee"
    >

      <cadre-employee-label
        removable
        withAvatar
        :data="employee.cadre_employee"
        @delete="() => $emit('delete:employee', employee)"
      >
        <template #subtitle>
          <slot name="employee-subtitle" :employee="employee" />
        </template>
      </cadre-employee-label>

      <cadre-position-picker hideCost
        class="position-picker"
        placeholder="Wybierz stanowisko"
        :value="employee.cadre_position_id"
        @pick="(e) => $emit('update:position', employee.cadre_employee_id, e.id)"
      />

    </div>

    <cadre-employee-picker
      placeholder="Dodaj pracownika do raportu"
      @input="(employee) => $emit('add:employee', employee)"
      :reduce="employee => employee"
      :blacklist="employees.map((e) => e.cadre_employee.id)"
    >
    </cadre-employee-picker>
  </div>
</template>

<script>
import CadreEmployeeLabel from '@/components/views/cadre/ui/CadreEmployeeLabel.vue';
import CadrePositionPicker from '@/components/module/cadre/CadrePositionPicker.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';

export default {
  components: { CadreEmployeeLabel, CadreEmployeePicker, CadrePositionPicker },
  name: 'VsEmployeeMultiselect',
  emits: ['add:employee', 'delete:employee'],
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.vs-employee-multiselect {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 5px;

  .employee {
    border-radius: 5px;
    position: relative;
    padding: 5px;
    display: flex;
    align-items: center;

    &:hover {
      ::v-deep .cadre-employee-label {
        .remove-button { opacity: 1 }
        .remove-button .icon { opacity: 0.5; transform: translateY(0) rotateY(0deg) }
      }
    }

    .position-picker {
      width: 300px;
      margin-left: auto;
    }
  }
}
</style>
