var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-employee-multiselect"
  }, [_vm._l(_vm.employees, function (employee) {
    return _c('div', {
      key: employee.id,
      staticClass: "employee"
    }, [_c('cadre-employee-label', {
      attrs: {
        "removable": "",
        "withAvatar": "",
        "data": employee.cadre_employee
      },
      on: {
        "delete": function () {
          return _vm.$emit('delete:employee', employee);
        }
      },
      scopedSlots: _vm._u([{
        key: "subtitle",
        fn: function () {
          return [_vm._t("employee-subtitle", null, {
            "employee": employee
          })];
        },
        proxy: true
      }], null, true)
    }), _c('cadre-position-picker', {
      staticClass: "position-picker",
      attrs: {
        "hideCost": "",
        "placeholder": "Wybierz stanowisko",
        "value": employee.cadre_position_id
      },
      on: {
        "pick": function (e) {
          return _vm.$emit('update:position', employee.cadre_employee_id, e.id);
        }
      }
    })], 1);
  }), _c('cadre-employee-picker', {
    attrs: {
      "placeholder": "Dodaj pracownika do raportu",
      "reduce": function (employee) {
        return employee;
      },
      "blacklist": _vm.employees.map(function (e) {
        return e.cadre_employee.id;
      })
    },
    on: {
      "input": function (employee) {
        return _vm.$emit('add:employee', employee);
      }
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }