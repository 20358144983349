<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCadrePositionStore from '@/stores/module/cadre/useCadrePositionStore';
import CadrePositionPickerOption from './CadrePositionPickerOption.vue';

export default Vue.component('CadrePositionPicker', {
  emits,
  setup(_, ctx) {
    const store = useCadrePositionStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz stanowisko z listy',
      reduce: (option) => option.id,
      options: store.state,
      ...ctx.attrs,
    }, {
      option: ({ option }) => h(CadrePositionPickerOption, { props: { position: option } }),
    }));
  },
  components: { VsPickerVue },
});
</script>
