<template>
  <div class="investments-report-form-fuel">
    <investments-report-form-details
      :investment="investment"
      :reportDate="reportDate"
    />

    <vs-loader
      v-if="isLoading && !fuelReports.length"
      full
      text="Wczytywanie raportów"
    />

    <div
      v-if="fuelReports.length || disableAdd"
      class="report-table-wrapper"
    >
      <vs-form-header
        :withButton="!disableAdd"
        :text="`Raporty ${reportType === 'fuel' ? 'tankowań' : 'motogodzin'}
          z dnia ${formatDate.training(reportDate)}`"
        buttonText="+ Dodaj raport"
        @action="openReportModal"
      />
      <vs-table
        :showActionButton="!disableEdit"
        class="owned-list"
        :columns="reportColumns"
        :rows="fuelReports"
        :actionItems="reportActionButtons"
        :show-search="false"
        :show-pagination="false"
        :isLoading="isLoading"
        :sortOptions="{ enabled: false }"
        v-if="!disableAdd || fuelReports.length"
      >
        <template v-slot:row="{ column, row }" >
          <div v-if="column.field === 'report_date'" >
            {{ formatDate.baseReport(row.report_date) }}
          </div>
          <div v-else-if="column.field === 'reporter_id'" >
            <user-label withAvatar :id="row.reporter_id" />
          </div>
          <div v-else-if="column.field === 'name'" >
            <base-resource-label
              :id="row.resource.vehicle_id"
              :resource="row.resource"
              target="_blank"
            />
          </div>
          <div v-else-if="column.field === 'amount_fuel'" >
            {{ Number(row.amount_fuel || 0).toLocaleString() }} l
          </div>
          <div v-else-if="column.field === 'meter_status'" >
            {{ Number(row.meter_status || 0).toLocaleString() }}
            {{ unitStore.getShortcode(row.resource?.category?.unit_id) }}
          </div>
        </template>
      </vs-table>

      <div class="no-reports-info" v-else-if="!isLoading">
        Inwestycja nie posiada żadnych raportów
        {{ reportType === 'fuel' ? 'tankowania' : 'przerobowych' }}
        w danym okresie
      </div>
    </div>

    <div
      class="resource-table-wrapper"
      v-if="resourcesWithoutReports.length && !disableAdd"
    >
      <vs-form-header
        withButton
        class="without-reports-header"
        text="Maszyny i sprzęty bez raportów"
        buttonText="+ Dodaj raport"
        @action="openReportModal"
      />
      <vs-table
        class="owned-list"
        :columns="withoutReportColumns"
        :rows="resourcesWithoutReports"
        :show-search="false"
        :show-pagination="false"
        :showActionButton="false"
        :sortOptions="{ enabled: false }"
      >
        <template v-slot:row="{ column, row }" >
          <div
            v-if="column.field === 'add'"
            class="add-report-wrapper"
          >
            <vs-button
              vs-variant="light"
              @click="() => openReportModalForResource(row.resource_id)"
              >
              Dodaj raport
            </vs-button>
          </div>
          <div v-else-if="column.field === 'name'" >
            <base-resource-label
              :id="row.vehicle_id"
              :resource="row"
              target="_blank"
            />
          </div>
        </template>
      </vs-table>
    </div>

    <machines-mth-report-modal-edit
      noTableReload
      @success="fetchReports"
      :machineWhitelist="investmentResources.map((r) => r.vehicle_id)"
      :employeeWhitelist="investmentEmployeeIds"
    />

    <machines-mth-report-modal
      @ok="fetchReports"
    />
  </div>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import VsFormHeader from '@/components/ui/vs-form/VsFormHeader.vue';
import formatDate from '@/libs/date-formatter';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useMachineReports from '@/hooks/base/machine/useMachineReports';
import useBaseApi from '@/hooks/base/useBaseApi';
import UserLabel from '@/components/ui/UserLabel.vue';
import MachinesMthReportModalEdit from '@/components/views/base/machines/modals/MachinesMthReportModalEdit.vue';
import BaseResourceLabel from '@/components/views/base/resource/BaseResourceLabel.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import showToast from '@/libs/toasts';
import { FetchInvestmentError } from '@/libs/toasts/feedback/module/investments';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import MachinesMthReportModal from '@/components/views/base/machines/modals/MachinesMthReportModal.vue';
import useCoreUnitStore from '@/stores/module/core/useCoreUnitStore';
import InvestmentsReportFormDetails from './InvestmentsReportFormDetails.vue';

export default {
  name: 'InvestmentsReportFormFuel',
  emits: ['update:investment'],
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
    reportDate: {
      type: String,
      default: '-',
    },
    disableAdd: {
      type: Boolean,
      default: false,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    reportType: {
      type: String,
      default: 'fuel',
      validator: (value) => ['fuel', 'work'].includes(value),
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(true);
    const unitStore = useCoreUnitStore();

    const fuelReports = ref([]);
    const fetchReports = (investment = props.investment) => {
      const unifiedDate = formatDate.standard(props.reportDate);
      isLoading.value = true;

      const investmentsResourceIds = investment?.base_resource
        ? investment.base_resource
          .map((v) => v.base_resource_id)
          .reduce((acc, curr) => {
            if (acc.includes(curr)) return acc;
            return [...acc, curr];
          }, [])
        : [];

      useBaseApi()
        .fetchMthReports({
          columnFilters: {
            date_from: unifiedDate,
            date_to: unifiedDate,
            report_type: props.reportType,
            resource_ids: investmentsResourceIds,
          },
        })
        .then(({ data }) => {
          const fetchedReports = data.data?.reports ?? [];
          const getReportResource = (report) => ({
            ...report.resource,
            vehicle_id: report.resource?.id,
          } ?? {});

          fuelReports.value = fetchedReports.map((report) => ({
            ...report,
            resource: getReportResource(report),
          }));
          isLoading.value = false;
        })
        .catch(() => {
          // TODO: feedback
          isLoading.value = false;
        });
    };

    const fetchInvestmentDetails = () => new Promise((resolve, reject) => {
      if (props.investment.base_resource) {
        isLoading.value = false;
        reject();
      }
      // get more detailed investment data
      useInvestmentsApi()
        .fetchInvestment(props.investment.id)
        .then(({ data }) => {
          isLoading.value = false;
          emit('update:investment', data.data);
          resolve(data.data);
        })
        .catch(() => {
          isLoading.value = false;
          showToast(FetchInvestmentError);
          reject();
        });
    });

    watch(() => props.reportType, () => {
      fuelReports.value = [];

      if (props.investment?.base_resource) {
        fetchReports();
      }
    });

    onMounted(() => {
      fetchInvestmentDetails()
        .then((investment) => fetchReports(investment))
        .catch(() => fetchReports());
    });

    const investmentResources = computed(() => {
      // FIXME add tools
      const uniqueResourceReduce = (unique, current) => {
        // eslint-disable-next-line max-len
        const resourceIdOf = (r) => r.resource.id;
        const compareResources = (a, b) => resourceIdOf(a) === resourceIdOf(b);

        if (unique.find((a) => compareResources(a, current))) return unique;
        return [...unique, current];
      };

      return [
        ...(props.investment?.base_resource ?? [])
          .reduce(uniqueResourceReduce, [])
          .filter(({ resource }) => ['tool', 'vehicle'].includes(resource.type.slug))
          .map((v) => ({
            resource_id: v.resource.id,
            vehicle_id: v.resource.id,
            name: v.resource.name,
            group: v.resource.last_activity?.group?.name,
          })),
      ];
    });

    const addNewReport = (report) => {
      const getReportResourceById = (vehicledReport) => {
        const resourceId = vehicledReport.resource_id;
        const resource = investmentResources.value
          .find((r) => r.resource_id === resourceId);

        return { ...resource, id: resourceId };
      };

      fuelReports.value.push({
        ...report,
        resource: getReportResourceById(report),
      });
    };

    const investmentEmployees = computed(() => {
      if (!props?.investment?.brigades) return [];
      const brigades = props.investment.brigades ?? [];

      return brigades
        .reduce((employees, brigade) => [...employees, ...brigade.employees], []);
    });

    const investmentEmployeeIds = computed(() => investmentEmployees.value
      .map((e) => e.cadre_employee_id));

    // if in fuelReports, dont return
    const resourcesWithoutReports = computed(() => {
      const reportExists = (searchedResourceId) => fuelReports.value
        .find(({ resource }) => resource.id === searchedResourceId);

      return investmentResources.value
        .filter((resource) => !reportExists(resource.resource_id));
    });

    const withoutReportColumns = [
      { label: 'Nazwa', field: 'name' },
      { label: 'Grupa', field: 'group' },
      { label: '', field: 'add' },
    ];

    const reportColumns = computed(() => {
      if (props.reportType === 'fuel') {
        return [
          { label: 'Data', field: 'report_date' },
          { label: 'Nazwa', field: 'name' },
          { label: 'Litry', field: 'amount_fuel' },
          { label: 'MTH', field: 'meter_status' },
          { label: 'Raportujący', field: 'reporter_id' },
        ];
      }

      return [
        { label: 'Data', field: 'report_date' },
        { label: 'Nazwa', field: 'name' },
        { label: 'MTH', field: 'meter_status' },
        { label: 'Raportujący', field: 'reporter_id' },
      ];
    });

    const openReportModal = () => {
      const { modalCreateReport } = useBaseModals();

      modalCreateReport.value.open({
        reportFuel: true,
        report_date: formatDate.standard(props.reportDate),
        time: formatDate.getTime(props.reportDate),
      });
    };

    const openReportModalForResource = (objectId) => {
      // TODO: automatically assign object_id
      const { modalCreateReport } = useBaseModals();

      modalCreateReport.value.open({
        reportFuel: true,
        base_resource_id: objectId,
      });
    };

    const { editedReport, mthReportModalEditVisible, deleteReport } = useMachineReports();

    const reportActionButtons = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          const objectId = obj.resource.id;
          editedReport.value = { ...obj, object_id: objectId };
          mthReportModalEditVisible.value = true;
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash2',
        callback: async (obj) => {
          const successful = await deleteReport(obj);

          if (successful) {
            fuelReports.value = fuelReports.value.filter(({ id }) => id !== obj.id);
          }
        },
      },
    ];

    return {
      withoutReportColumns,
      reportColumns,
      resourcesWithoutReports,
      fuelReports,
      formatDate,
      openReportModal,
      openReportModalForResource,
      investmentResources,
      investmentEmployeeIds,
      addNewReport,
      reportActionButtons,
      fetchReports,
      isLoading,
      unitStore,
    };
  },
  components: {
    VsTable,
    InvestmentsReportFormDetails,
    VsFormHeader,
    VsButton,
    UserLabel,
    MachinesMthReportModalEdit,
    BaseResourceLabel,
    VsLoader,
    MachinesMthReportModal,
  },
};
</script>

<style lang="sass" scoped>
.no-reports-info
  padding: 15px
  opacity: 0.6

.investments-report-form-fuel
  padding: 30px
  position: relative

  .vs-loader
    top: 0

.add-report-wrapper
  text-align: right

.report-table-wrapper
  margin-bottom: 20px
</style>
