<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useInvestmentsBrigadeStore from '@/stores/module/investments/useInvestmentsBrigadeStore';

export default Vue.component('InvestmentsBrigadePicker', {
  emits,
  props: {
    withMainVehicleOnly: {
      type: Boolean,
      default: false,
    },
    investmentId: {
      type: Number,
    },
  },
  setup(props, ctx) {
    const store = useInvestmentsBrigadeStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz brygadę z listy',
      reduce: (option) => option.id,
      options: store.state,
      excluded: (o) => {
        if (props.investmentId && o.investment_id !== props.investmentId) return true;
        if (!props.withMainVehicleOnly) return false;
        return !o.resource.some(({ pivot }) => pivot.is_main);
      },
      ...ctx.attrs,
    }));
  },
  components: { VsPickerVue },
});
</script>
