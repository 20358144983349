<template>
  <vs-modal
    :modalInstance="modalCreateFueling"
    @ok="createRefueling"
    @hide="resetFields"
  >
    <investments-fueling-form
      :value="fields"
      :feedback="feedback"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useInvestmentsModals from '@/hooks/investments/useInvestmentsModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import formatDate from '@/libs/date-formatter';
import store from '@/store';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import {
  CreateFuelingSuccess,
  CreateFuelingError,
} from '@/libs/toasts/feedback/module/investments';
import showToast from '@/libs/toasts';
import { watch } from 'vue';
import investmentsFueling from '@/hooks/tables/investments/investmentsFueling';
import InvestmentsFuelingForm from '../../forms/fueling/InvestmentsFuelingForm.vue';

export default {
  name: 'InvestmentsCreateRefuelingModal',
  emits: ['success'],
  setup(_, { emit }) {
    const { modalCreateFueling } = useInvestmentsModals();

    const {
      fields,
      feedback,
      areAllFieldsValid,
      setFieldDefaults,
      resetFields,
      assignFields,
    } = useValidatedForm({
      order_date: 'required',
      amount_fuel: 'required|integer|positive',
      crm_provider_id: 'required',
      reporting_id: 'required',
      investment_id: 'required',
      comment: '',
    });

    setFieldDefaults({
      order_date: formatDate.standardTimed(Date.now()),
      reporting_id: store.getters['auth/user']?.id,
    });

    watch(() => modalCreateFueling.value.visible, () => {
      resetFields();
      assignFields(modalCreateFueling.value.data);
      modalCreateFueling.value.data = {};
    });

    const createRefueling = async () => {
      const isValid = await areAllFieldsValid();
      if (!isValid) return;

      modalCreateFueling.value.isLoading = true;

      useInvestmentsApi()
        .createFueling(fields.value)
        .then((data) => {
          showToast(CreateFuelingSuccess);
          modalCreateFueling.value.close();
          investmentsFueling.value.fetchData();
          emit('success', data.data);
        })
        .catch(() => {
          showToast(CreateFuelingError);
        })
        .finally(() => {
          modalCreateFueling.value.isLoading = false;
        });
    };

    return {
      modalCreateFueling,
      createRefueling,
      fields,
      resetFields,
      feedback,
    };
  },
  components: { VsModal, InvestmentsFuelingForm },
};
</script>
