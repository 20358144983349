import store from '@/store';
import {
  computed, ref, watch, reactive,
} from 'vue';

import useDelayedSearch from '../useDelayedSearch';

/* Create Table function */
const createTable = (storeModule, columns, options = {}) => {
  const { delayedSearch } = useDelayedSearch();

  const tableFilters = options?.filters ?? {};
  const sortableColumns = options?.sortable ?? {};

  // send sorting column name as different string
  const invertedSortingFields = options?.invertedSortable ?? [];
  const isInvertedSortable = (field) => invertedSortingFields.includes(field);
  const sortTypeInversion = {
    asc: 'desc',
    desc: 'asc',
  };

  const shouldUpdateTableOnFilterChange = ref(true);
  const filters = ref({ ...tableFilters });

  const tablePerPage = store.state.app.tablePerPage ?? 3;

  const table = ref({
    sort: [],
    searchTerm: ref(delayedSearch),
    columns: [],
    page: 1,
    perPage: tablePerPage,
    totalRecords: 0,
    isLoading: false,
    ...options,
  });

  let rows = reactive([]);
  const defaultFilterValues = ref({ ...tableFilters });

  table.value.columns = columns;

  const getFetchParams = () => {
    const requestFilters = {};

    Object.keys(filters.value).map((filter) => {
      requestFilters[filter] = filters.value[filter];
      return requestFilters[filter];
    });

    const fetchParams = {
      columnFilters: requestFilters,
      sort: table.value.sort.map(({ field, type }) => {
        if (!isInvertedSortable(field)) return { field, type };
        return {
          field: `-${field}`,
          type: sortTypeInversion[type],
        };
      }),
      search: table.value.searchTerm.value,
      page: table.value.page,
      perPage: table.value.perPage,
    };

    return fetchParams;
  };

  const fetchData = () => {
    const fetchParams = getFetchParams();

    if (storeModule) {
      table.value.isLoading = true;

      store
        .dispatch(`${storeModule}/fetch`, fetchParams)
        .then(({ data }) => {
          table.value.isLoading = false;
          table.value.totalRecords = data.data.totalRecords ?? data.data.length;

          rows = data.data;
        })
        .catch(() => {
          table.value.isLoading = false;
        });
    }
  };

  watch(filters.value, () => {
    if (!shouldUpdateTableOnFilterChange.value) return;
    fetchData();
  });

  const onPageChange = (data) => {
    const { page, perPage } = table.value;
    if (data.currentPage === page && data.currentPerPage === perPage) return;

    store.commit('app/SET_TABLE_PER_PAGE', Number(data.currentPerPage));

    table.value.page = Number(data.currentPage);
    table.value.perPage = Number(data.currentPerPage);

    fetchData();
  };

  /* Create Table Filter */
  const createFilter = (filterName) => filters.value[filterName];

  /* Reset all filters */
  const clearAllFilters = () => {
    Object.keys(filters.value).forEach((filter) => {
      const filterDefaultValue = defaultFilterValues.value?.[filter];
      filters.value[filter] = filterDefaultValue;
    });
  };

  const searchTerm = computed(() => table.value.searchTerm);
  let last = null;

  watch(
    searchTerm.value,
    (val) => {
      if (val !== last) return;
      fetchData();
    },
    true,
  );

  const updateSearchTerm = (value) => {
    last = value;
    table.value.searchTerm = value;
  };

  const changeSorting = ({ field, type }) => {
    if (!sortableColumns.includes(field)) return false;
    table.value.sort = [{ field, type }];
    fetchData();

    return true;
  };

  const getColumnSorting = (field) => {
    if (!sortableColumns.includes(field)) return null;
    return table.value.sort.find((column) => column.field === field);
  };

  return {
    table,
    shouldUpdateTableOnFilterChange,
    rows,
    createFilter,
    clearAllFilters,
    updateSearchTerm,
    searchTerm,
    columns: table.value.columns,
    fetchData,
    onPageChange,
    filters,
    changeSorting,
    getColumnSorting,
    isInvertedSortable,
    getFetchParams,
  };
};

export default createTable;
