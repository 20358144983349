var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-report-form-details"
  }, [_c('table', [_c('tr', [_c('td', [_vm._v("inwestycja")]), _c('td', [_c('investments-investment-label', {
    attrs: {
      "investment": _vm.investment,
      "id": _vm.investment.id,
      "target": "_blank"
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("data raportu")]), _c('td', [_vm._v(_vm._s(_vm.formatDate.baseReport(_vm.reportDate)))])]), _c('tr', [_c('td', [_vm._v("raportujący")]), _c('user-label', {
    attrs: {
      "withAvatar": ""
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }