var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-report-form-mth"
  }, [_c('investments-report-form-details', {
    attrs: {
      "investment": _vm.investment,
      "reportDate": _vm.reportDate
    }
  }), _c('vs-table', {
    staticClass: "owned-list",
    attrs: {
      "columns": _vm.resourceColumns,
      "rows": _vm.ownedResources,
      "show-search": false,
      "show-pagination": false,
      "showActionButton": false,
      "sortOptions": {
        enabled: false
      },
      "isLoading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'meter_status' ? _c('span', {
          staticClass: "editable-cell"
        }, [_c('b-input-group', {
          attrs: {
            "append": _vm.unitStore.getShortcode(row.unit_id)
          }
        }, [_c('b-form-input', {
          attrs: {
            "value": row.meter_status,
            "placeholder": row.previousMeterStatus,
            "state": _vm.isMeterStatusValid(row)
          },
          on: {
            "input": function (val) {
              return _vm.updateResource(row, 'meter_status', val);
            }
          }
        })], 1), _c('b-form-invalid-feedback', {
          attrs: {
            "state": _vm.isMeterStatusValid(row)
          }
        }, [_vm._v(" " + _vm._s(_vm.getMeterStatusValidationMessage(row)) + " ")])], 1) : column.field === 'name' ? _c('div', [_c('base-resource-label', {
          attrs: {
            "id": row.vehicle_id,
            "resource": row,
            "target": "_blank"
          }
        })], 1) : column.field === 'operator_id' ? _c('span', {
          staticClass: "editable-cell"
        }, [_c('cadre-employee-picker', {
          attrs: {
            "whitelist": _vm.investmentEmployeeIds,
            "value": row.operator_id
          },
          on: {
            "input": function (val) {
              return _vm.updateResource(row, 'operator_id', val);
            }
          },
          scopedSlots: _vm._u([{
            key: "selectedProfession",
            fn: function (_ref2) {
              var id = _ref2.id;
              return [_vm._v(" " + _vm._s(_vm.getEmployeeBrigadePosition(id)) + " ")];
            }
          }, {
            key: "listedProfession",
            fn: function (_ref3) {
              var id = _ref3.id;
              return [_vm._v(" " + _vm._s(_vm.getEmployeeBrigadePosition(id)) + " ")];
            }
          }], null, true)
        })], 1) : _vm._e()];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }