import showToast from '@/libs/toasts';
import {
  CreateMachineReportError,
  CreateMachineReportSuccess,
  DeleteMachineReportError,
  DeleteMachineReportSuccess,
  UpdateMachineReportError,
  UpdateMachineReportSuccess,
} from '@/libs/toasts/feedback/module/base';
import { computed, ref, watch } from 'vue';
import formatDate from '@/libs/date-formatter';
import store from '@/store';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import refuelingTable from '@/hooks/tables/base/refuelingTable';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '../useBaseApi';
import useBaseModals from '../useBaseModals';
import useMachineMthCard from './useMachineMthCard';

const editedReport = ref({});
const override = ref({});

export default function useMachineReports(machine = null) {
  const { reportMth, reportMthEdit, reportFuel } = useBaseModals();

  const validations = useValidatedForm({
    object_id: `${machine ? 'integer' : 'required|integer'}`,
    amount_fuel: 'required_or:@base_work_type_id|integer',
    base_work_type_id: 'required_or:@amount_fuel',
    comment: '',
    purpose: '',
    meter_status: 'required|integer',
    operator_id: 'required|integer',
    report_date: 'required',
    reporter_id: 'required|integer',
  }, 'base.form.report.field');

  const { errors } = validations;
  const form = validations.fields;
  const processing = ref(false);

  const setReportCurrentDate = () => {
    const date = new Date();
    form.value.time = `${date.getHours()}:${date.getMinutes()}`;
    form.value.report_date = formatDate.standard(Date.now());
  };

  const resetForm = () => {
    validations.resetFields();

    setReportCurrentDate();

    // set current user as a default reporter
    const user = store.getters['auth/user'];
    form.value.reporter_id = user.id;

    // set machine name if it exists
    if (machine) {
      form.value.machine = computed(() => machine.value?.name);
    }
  };

  watch(computed(() => reportMth.value || reportFuel.value || reportMthEdit.value), () => {
    validations.clearErrors();
    resetForm();
    setReportCurrentDate();
    form.value = { ...form.value, ...override.value };
  });

  resetForm();

  const addReport = async () => {
    const objectId = machine?.value?.id ?? form.value?.object_id;
    const valid = await validations.validateAllFields();

    if (!valid.every((field) => field)) return new Promise();
    processing.value = true;

    const request = useBaseApi()
      .createMachineReport({
        ...form.value,
        object_id: objectId,
        report_date: `${form.value.report_date} ${form.value.time ?? '00:00:00'}`,
      })
      .then(() => {
        reportMth.value = false;
        reportFuel.value = false;
        processing.value = false;

        showToast(CreateMachineReportSuccess, { name: machine?.value?.name || '' });

        // force the update of report charts
        useMachineMthCard().updateChart(objectId, true);
        refuelingTable.value.fetchData();
      })
      .catch(({ response }) => {
        errors.serverSide.value = response?.data?.errors;
        processing.value = false;

        showToast(CreateMachineReportError);
      });

    return request;
  };

  const updateReport = async () => {
    const valid = await validations.validateAllFields();

    if (!valid.every((field) => field)) return;
    processing.value = true;

    useBaseApi()
      .updateMachineReport({
        ...form.value,
      })
      .then(() => {
        reportMthEdit.value = false;
        editedReport.value = null;
        processing.value = false;

        refuelingTable.value.fetchData();

        showToast(UpdateMachineReportSuccess, { name: machine?.value?.name || '' });
        resetForm();
      })
      .catch(({ response }) => {
        errors.serverSide.value = response?.data?.errors;
        processing.value = false;

        showToast(UpdateMachineReportError);
      });
  };

  const fillEditingReport = (forEquipment = false) => {
    if (!editedReport.value) return;
    const time = formatDate.getTime(editedReport.value.report_date);

    const resourceKey = forEquipment ? 'tools' : 'vehicles';

    form.value = {
      ...validations.fields,
      id: editedReport.value.id,
      machine: { value: editedReport.value?.[resourceKey]?.[0].resource.name },
      object_id: editedReport.value.object_id,
      report_date: editedReport.value.report_date,
      time,
      reporter_id: editedReport.value.reporter_id,
      operator_id: editedReport.value.operator.id,
      base_work_type_id: editedReport.value.base_work_type_id,
      meter_status: editedReport.value.meter_status,
      comment: editedReport.value.comment,
      amount_fuel: editedReport.value.amount_fuel,
      has_fuel: !!editedReport.value.amount_fuel,
    };
  };

  const deleteReport = async (report) => {
    if (!report) return false;
    const date = formatDate.baseReport(report.report_date);

    const confirmation = await deleteModal({
      title: i18n.t('base.machines.modal.deleteReport.title'),
      text: i18n.t('base.machines.modal.deleteReport.text', { date }),
    });

    if (!confirmation) return false;

    const req = useBaseApi()
      .deleteMachineReport(report)
      .then(() => {
        showToast(DeleteMachineReportSuccess, { date });

        const { fetchData } = refuelingTable.value;
        fetchData();
        return true;
      })
      .catch(() => {
        showToast(DeleteMachineReportError);
        return false;
      });

    return req;
  };

  // const feedback = computed(() => {
  //   const errorList = {};

  //   Object.keys(errors.value).forEach((error) => {
  //     if (!errors.value?.[error]) return;
  //     errorList[error] = errors.value?.[error]?.[0];
  //   });

  //   return errorList;
  // });

  return {
    mthReportModalVisible: reportMth,
    mthReportModalEditVisible: reportMthEdit,
    fuelReportModalVisible: reportFuel,

    addReport,
    updateReport,
    deleteReport,
    form,
    resetForm,
    editedReport,
    fillEditingReport,
    override,

    feedback: validations.feedback,
    processing,
    validations,
    errors,
  };
}
