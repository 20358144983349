var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-report-form-list"
  }, [_c('vs-table', {
    attrs: {
      "columns": _vm.columns,
      "rows": _vm.rows,
      "showEditButton": false,
      "showPagination": false,
      "showSearch": false
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [props.column.field === 'name' ? _c('span', [_vm.editable ? _c('b-form-input', {
          attrs: {
            "placeholder": "Nazwa"
          },
          on: {
            "input": function (val) {
              return _vm.updateRow(props.index, {
                name: val
              });
            }
          },
          model: {
            value: props.row.name,
            callback: function ($$v) {
              _vm.$set(props.row, "name", $$v);
            },
            expression: "props.row.name"
          }
        }) : _c('span', [_vm._v(_vm._s(props.row.name))])], 1) : props.column.field === 'amount' ? _c('span', [_vm.editable ? _c('b-form-input', {
          staticClass: "amount-input",
          attrs: {
            "placeholder": "Ilość"
          },
          on: {
            "input": function (val) {
              return _vm.updateRow(props.index, {
                amount: val
              });
            }
          },
          model: {
            value: props.row.amount,
            callback: function ($$v) {
              _vm.$set(props.row, "amount", $$v);
            },
            expression: "props.row.amount"
          }
        }) : _c('span', [_vm._v(_vm._s(props.row.amount))])], 1) : props.column.field === 'core_unit_id' ? _c('span', [_vm.editable ? _c('core-unit-picker', {
          on: {
            "input": function (val) {
              return _vm.updateRow(props.index, {
                core_unit_id: val
              });
            }
          },
          model: {
            value: props.row.core_unit_id,
            callback: function ($$v) {
              _vm.$set(props.row, "core_unit_id", $$v);
            },
            expression: "props.row.core_unit_id"
          }
        }) : _c('span', [_vm._v(" " + _vm._s(_vm.getUnitById(props.row.core_unit_id).code) + " ")])], 1) : props.column.field === 'price' ? _c('span', [_vm.editable ? _c('b-input-group', {
          attrs: {
            "append": "PLN"
          }
        }, [_c('b-form-input', {
          staticClass: "price-input",
          attrs: {
            "placeholder": "Suma"
          },
          on: {
            "input": function (val) {
              return _vm.updateRow(props.index, {
                price: val
              });
            }
          },
          model: {
            value: props.row.price,
            callback: function ($$v) {
              _vm.$set(props.row, "price", $$v);
            },
            expression: "props.row.price"
          }
        })], 1) : _c('span', [_vm._v(_vm._s(props.row.price) + " PLN")])], 1) : props.column.field === 'edit' && _vm.editable ? _c('span', [_c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": function () {
              return _vm.deleteRow(props.index);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1)], 1) : _vm._e()];
      }
    }])
  }), _vm.editable ? _c('vs-button', {
    staticClass: "add-entry-button",
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.createBlankRow
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Dodaj koszt ")], 1) : _vm._e(), _vm.editable ? _c('b-form-textarea', {
    attrs: {
      "placeholder": "Uwagi do dodatkowych kosztów"
    },
    model: {
      value: _vm.note,
      callback: function ($$v) {
        _vm.note = $$v;
      },
      expression: "note"
    }
  }) : _c('div', {
    staticClass: "note"
  }, [_c('hr'), _c('h6', [_vm._v("Uwagi do dodatkowych kosztów")]), _c('p', [_vm._v(" " + _vm._s(_vm.note || 'brak uwag') + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }