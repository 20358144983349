<template>
  <div class="cadre-position-picker-option">
    <div class="cadre-position-picker-option__group-name" v-if="group">
      {{ group.name }}
    </div>
    <div class="cadre-position-picker-option__position-name">
      {{ position.name }}
    </div>
  </div>
</template>

<script>
import useCadreGroupStore from '@/stores/module/cadre/useCadreGroupStore';
import { computed } from 'vue';

export default {
  name: 'CadrePositionPickerOption',
  props: {
    position: {
      type: Object,
    },
  },
  setup(props) {
    const groupStore = useCadreGroupStore();
    const group = computed(() => groupStore.getById(props.position.group_id));
    return { group };
  },
};
</script>

<style lang="scss" scoped>
.cadre-position-picker-option {
  display: flex;
  align-items: center;
  gap: 5px;

  &__group-name {
    opacity: 0.7;

    &:after {
      content: '|';
    }
  }
}
</style>
