<template>
  <div class="investments-report-form-list">
    <vs-table
      :columns="columns"
      :rows="rows"
      :showEditButton="false"
      :showPagination="false"
      :showSearch="false"
    >
      <template v-slot:row="props" >
        <span v-if="props.column.field === 'name'">
          <b-form-input
            placeholder="Nazwa"
            v-model="props.row.name"
            @input="(val) => updateRow(props.index, { name: val })"
            v-if="editable"
          />
          <span v-else>{{ props.row.name }}</span>
        </span>
        <span v-else-if="props.column.field === 'amount'">
          <b-form-input
            placeholder="Ilość"
            class="amount-input"
            v-model="props.row.amount"
            @input="(val) => updateRow(props.index, { amount: val })"
            v-if="editable"
          />
          <span v-else>{{ props.row.amount }}</span>
        </span>
        <span v-else-if="props.column.field === 'core_unit_id'">
          <core-unit-picker
            v-model="props.row.core_unit_id"
            @input="(val) => updateRow(props.index, { core_unit_id: val })"
            v-if="editable"
          />
          <span v-else>
            {{ getUnitById(props.row.core_unit_id).code }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'price'">
          <b-input-group
            append="PLN"
            v-if="editable"
          >
            <b-form-input
              placeholder="Suma"
              class="price-input"
              v-model="props.row.price"
              @input="(val) => updateRow(props.index, { price: val })"
            />
          </b-input-group>
          <span v-else>{{ props.row.price }} PLN</span>
        </span>
        <span v-else-if="props.column.field === 'edit' && editable">
          <vs-button
            vs-variant="light"
            @click="() => deleteRow(props.index)"
          >
            <feather-icon icon="XIcon" />
          </vs-button>
        </span>
      </template>
    </vs-table>

    <vs-button
      class="add-entry-button"
      vs-variant="light"
      @click="createBlankRow"
      v-if="editable"
    >
      <feather-icon icon="PlusIcon" />
      Dodaj koszt
    </vs-button>

    <b-form-textarea
      placeholder="Uwagi do dodatkowych kosztów"
      v-model="note"
      v-if="editable"
    />
    <div class="note" v-else>
      <hr>
      <h6>Uwagi do dodatkowych kosztów</h6>
      <p>
        {{ note || 'brak uwag' }}
      </p>
    </div>
  </div>
</template>

<script>
import { BInputGroup, BFormInput, BFormTextarea } from 'bootstrap-vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import { computed, ref, watch } from 'vue';
import CoreUnitPicker from '@/components/ui/pickers/Unit.vue';
import store from '@/store';

export default {
  name: 'InvestmentsReportFormList',
  emits: ['list:update'],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    noteValue: {
      type: String,
      default: '',
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const editable = computed(() => !props.disableEdit);
    const columns = [
      { label: 'Nazwa', field: 'name' },
      { label: 'Ilość', field: 'amount' },
      { label: 'Jednostka', field: 'core_unit_id' },
      { label: 'Suma', field: 'price' },
      { label: '', field: 'edit' },
    ];

    const rows = ref(props.value);
    watch(() => rows.value, () => {
      emit('list:update', rows.value);
    }, { deep: true });

    const note = ref(props.noteValue);
    watch(() => note.value, () => {
      emit('note:update', note.value);
    });

    const createBlankRow = () => {
      rows.value.push({
        name: '',
        amount: null,
        core_unit_id: null,
        price: null,
      });
    };

    const deleteRow = (rowIdx) => {
      rows.value.splice(rowIdx, 1);
    };

    const updateRow = (rowIdx, updating) => {
      rows.value = rows.value.map((row, idx) => {
        if (idx !== rowIdx) return row;
        return { ...row, ...updating };
      });
    };

    const getUnitById = (unitId) => store.getters['units/byId'](unitId);

    return {
      note,
      editable,
      columns,
      rows,
      createBlankRow,
      deleteRow,
      updateRow,
      getUnitById,
    };
  },
  components: {
    VsTable,
    VsButton,
    BFormInput,
    CoreUnitPicker,
    BFormTextarea,
    BInputGroup,
  },
};
</script>

<style lang="sass" scoped>
::v-deep
  .vs-table
    tbody
      td
        vertical-align: top

.add-entry-button
  width: 100%
  margin: 10px 0

  ::v-deep
    .vs-button-content
      display: flex
      align-items: center
      gap: 5px
</style>
