import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const refuelingColumns = [
  {
    label: i18n.t('base.global.field.date'),
    field: 'date',
    showInDetails: true,
  },
  {
    label: i18n.t('global.field.name'),
    field: 'machine',
  },
  {
    label: i18n.t('base.global.field.liters'),
    field: 'liters',
  },
  {
    label: i18n.t('base.machines.field.mth'),
    field: 'mth',
    showInDetails: true,
  },
  {
    label: i18n.t('base.machines.field.worktype'),
    field: 'worktype',
    showInDetails: true,
  },
  // {
  //   label: i18n.t('base.global.field.investment'),
  //   field: 'investment',
  // },
  {
    label: i18n.t('base.global.field.reporter'),
    field: 'reporter',
  },
];

export default ref(createTable('base/reports', refuelingColumns, {
  sort: [{ field: 'report_date', type: 'desc' }],
  filters: {
    date_from: null,
    date_to: null,
    resource_ids: [],
    tool_ids: [],
    operator_ids: [],
    report_type: null,
    work_type_ids: [],
    resource_type: [],
  },
}));
