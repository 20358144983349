import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';

const { fetchPickerBrigades } = useInvestmentsApi();

export default defineStore('investmentsBrigade', () => {
  const { state } = useRemoteResource('investments.brigade', fetchPickerBrigades);

  return {
    state,
  };
});
