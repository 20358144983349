import { ref, computed } from 'vue';

const settings = {
  delay: 500,
};

/**
 * Simple hook that updates the delayed after
 * specified delay (in ms).
 */
const useDelayedSearch = (entryValue = null) => {
  const delayed = ref(entryValue);
  let searchTimeout = null;

  const timeout = (value) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      delayed.value = value;
    }, settings.delay);
  };

  const delayedSearch = computed({
    get: () => delayed,
    set: (value) => {
      timeout(value);
    },
  });

  return {
    delayed, delayedSearch,
  };
};

export default useDelayedSearch;
