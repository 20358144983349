<template>
  <div class="investments-report-modal">
    <b-modal
      id="modal-investments-report"
      :title="$t('investments.modal.report.create.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('investments.modal.report.create.cancel')"
      :ok-title="$t('investments.modal.report.create.confirm')"
      v-model="investmentAddReportModal.visible"
      size="lg"
      @ok.prevent="summarizeStep"
      no-close-on-backdrop
    >
      <vs-loader full
        text="Tworzenie raportu..."
        v-if="processing"
      />

      <form-steps-indicator
        :current-step="step"
        @stepChange="(i) => step = i"
      />

      <!-- TODO: Major refactor needed, along with child components -->
      <investments-report-form
        v-model="fields"
        :feedback="feedback"
        v-if="step === 0"
        @usageUpdate="(u) => usage = u"
        @investmentUpdate="updateSelectedInvestment"
      />

      <investments-report-form-mth
        v-else-if="step === 1"
        :investment="selectedInvestment"
        :reportDate="`${fields.daily_balance_date} ${fields.report_time}`"
        @update:investment="updateSelectedInvestment"
        @update:reports="updateMthReports"
      />

      <investments-report-form-fuel
        v-else-if="step === 2"
        :investment="selectedInvestment"
        :reportDate="`${fields.daily_balance_date} ${fields.report_time}`"
      />

      <investments-report-form-list
        v-else-if="step === 3"
        @list:update="updateAdditionalCosts"
        @note:update="updateAdditionalCostsNote"
      />
      <!-- -->

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="processing"

          :text-cancel="$t('investments.modal.report.create.cancel')"
          :text-ok="$t('investments.modal.report.create.confirm')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { computed, ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import formatDate from '@/libs/date-formatter';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import showToast from '@/libs/toasts';
import { CreateInvestmentReportError, CreateInvestmentReportSuccess } from '@/libs/toasts/feedback/module/investments';
import { CreateMultipleMachineReportsSuccess, CreateMultipleMachineReportsError } from '@/libs/toasts/feedback/module/base';
import investmentsReports from '@/hooks/tables/investments/investmentsReports';
import FormStepsIndicator from '@/components/ui/FormStepsIndicator.vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import store from '@/store';
import InvestmentsReportForm from '../forms/investments-report-form/InvestmentsReportForm.vue';
import InvestmentsReportFormMth from '../forms/investments-report-form/InvestmentsReportFormMth.vue';
import InvestmentsReportFormFuel from '../forms/investments-report-form/InvestmentsReportFormFuel.vue';
import InvestmentsReportFormList from '../forms/investments-report-form/InvestmentsReportFormList.vue';

export default {
  name: 'InvestmentsReportModal',
  emits: ['success'],
  setup(_, { emit }) {
    const { investmentAddReportModal } = useInvestmentsStore();
    const selectedInvestment = ref(null);
    const updateSelectedInvestment = (value) => {
      selectedInvestment.value = value;
    };

    const step = ref(0);

    const processing = ref(false);
    const usage = ref([]);

    const usageWithZeros = computed(() => usage.value.map((u) => {
      const zeroed = {};

      Object.keys(u).forEach((property) => {
        if (property === 'daily_balance_usage') {
          zeroed[property] = u[property].map((up) => {
            const quantity = up.quantity === null ? 0 : up.quantity.replace(',', '.');
            return {
              ...up,
              quantity,
            };
          });
        } else {
          zeroed[property] = u[property] === null ? 0 : u[property];
          if (typeof zeroed[property] === 'string') {
            zeroed[property] = zeroed[property].replace(',', '.');
          }
        }
      });

      return zeroed;
    }));

    const {
      fields, feedback, assignFields, assignServerSideErrors, resetFields,
    } = useValidatedForm({
      investment_id: 'required',
      work_time_from: 'required',
      work_time_to: 'required',
      production_time_from: 'required',
      production_time_to: 'required',
      report_time: 'required',
      daily_balance_date: 'required',
      investment_brigade_id: 'required',
      assigned_employees: 'required',
      description: '',
    }, 'investments.form.report.field');

    watch(() => investmentAddReportModal.value.visible, () => {
      fields.value = {};
      step.value = 0;
      resetFields();

      const { data } = investmentAddReportModal.value;
      const currentDate = new Date();
      const workStart = new Date();
      const workEnd = new Date();

      workStart.setHours(7, 30, 0);
      workEnd.setHours(19, 0, 0);

      assignFields({
        work_time_from: formatDate.getTime(workStart),
        work_time_to: formatDate.getTime(workEnd),
        production_time_from: formatDate.getTime(workStart),
        production_time_to: formatDate.getTime(workEnd),
        report_time: formatDate.getTime(currentDate),
        daily_balance_date: formatDate.standard(currentDate),
      });

      if (data && Object.keys(data).length === 0) return;

      const editingValues = { ...investmentAddReportModal.value.data };
      assignFields(editingValues);

      investmentAddReportModal.value.data = {};
    });

    const timeToDate = (time) => `${fields.value.daily_balance_date} ${time}`;

    const reportId = ref(null);
    const sendReport = () => {
      useInvestmentsApi()
        .createReport({
          ...fields.value,
          daily_balance_progress: usageWithZeros.value,
          work_scheduler: {
            start_date: timeToDate(fields.value.work_time_from),
            end_date: timeToDate(fields.value.work_time_to),
          },
          production_scheduler: {
            start_date: timeToDate(fields.value.production_time_from),
            end_date: timeToDate(fields.value.production_time_to),
          },
          daily_balance_date: timeToDate(fields.value.report_time),
        })
        .then(({ data }) => {
          reportId.value = data.data.id;
          showToast(CreateInvestmentReportSuccess);
          investmentsReports.value.fetchData();
          step.value += 1;
          emit('success');
        })
        .catch(({ response }) => {
          const { errors } = response?.data ?? {};

          assignServerSideErrors({
            ...errors,
            work_time_from: errors?.['work_scheduler.start_date'],
            work_time_to: errors?.['work_scheduler.end_date'],
            production_time_from: errors?.['production_scheduler.start_date'],
            production_time_to: errors?.['production_scheduler.end_date'],
          });
          showToast(CreateInvestmentReportError);
        });
    };

    const mthReports = ref([]);
    const updateMthReports = (value) => {
      mthReports.value = value;
    };

    const sendMthReports = () => {
      const filteredReports = mthReports.value
        .map((report) => ({
          base_resource_id: report.resource_id,
          report_date: `${fields.value.daily_balance_date} ${fields.value.report_time}`,
          meter_status: report.meter_status,
          operator_id: report.operator_id,
          reporter_id: store.getters['auth/user']?.id,
          comment: null,
          amount_fuel: null,
          base_work_type_id: 1,
        }));

      if (filteredReports.length === 0) {
        step.value += 1;
        return;
      }

      useBaseApi()
        .createMultipleMachineReports(filteredReports)
        .then(() => {
          step.value += 1;
          showToast(CreateMultipleMachineReportsSuccess);
        })
        .catch(() => {
          showToast(CreateMultipleMachineReportsError);
        });
    };

    const additionalCosts = ref([]);
    const additionalCostsNote = ref('');
    const updateAdditionalCosts = (list) => {
      additionalCosts.value = list;
    };
    const updateAdditionalCostsNote = (note) => {
      additionalCostsNote.value = note;
    };

    const sendCosts = () => {
      useInvestmentsApi().addReportAdditionalCosts(reportId.value, {
        additional_costs: additionalCosts.value,
        additional_cost_note: additionalCostsNote.value,
      })
        .then(() => {
          investmentAddReportModal.value.visible = false;
        });
    };

    const summarizeStep = () => {
      if (step.value === 0) sendReport();
      else if (step.value === 1) sendMthReports();
      else if (step.value === 3) sendCosts();
      else step.value += 1;
    };

    return {
      fields,
      feedback,
      investmentAddReportModal,
      processing,
      sendReport,
      usage,
      summarizeStep,
      step,
      mthReports,
      updateMthReports,

      selectedInvestment,
      updateSelectedInvestment,

      additionalCosts,
      updateAdditionalCosts,
      updateAdditionalCostsNote,
    };
  },
  components: {
    VsLoader,
    InvestmentsReportForm,
    VsModalFooter,
    FormStepsIndicator,
    InvestmentsReportFormMth,
    InvestmentsReportFormFuel,
    InvestmentsReportFormList,
  },
};

</script>

<style lang="sass">
#modal-investments-report
  .modal-dialog
    max-width: 70% !important
</style>
