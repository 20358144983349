var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-steps-indicator"
  }, _vm._l(_vm.steps, function (step, index) {
    return _c('div', {
      key: `step-${index}`,
      staticClass: "step",
      class: Object.assign({}, _vm.computedClasses, {
        'step--active': index === _vm.currentStep
      }),
      on: {
        "click": function () {
          return _vm.stepClicked(index);
        }
      }
    }, [_c('div', {
      staticClass: "step-count"
    }, [_vm._v(_vm._s(index + 1))]), _c('div', {
      staticClass: "step-name"
    }, [_vm._v(" " + _vm._s(step) + " ")])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }