import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const investmentsReportsColumns = [
  {
    label: i18n.t('investments.reports.field.daily_balance_date'),
    field: 'daily_balance_date',
  },
  {
    label: i18n.t('investments.reports.field.work_hours'),
    field: 'work_hours',
  },
  {
    label: i18n.t('investments.reports.field.brigade'),
    field: 'brigade',
  },
  {
    label: i18n.t('investments.reports.field.investment'),
    field: 'investment',
  },
  {
    label: i18n.t('investments.reports.field.reporter'),
    field: 'reporter',
  },
];

export default ref(createTable('investments/reports', investmentsReportsColumns, {
  sortable: ['daily_balance_date'],
  filters: {
    date_from: null,
    date_to: null,
    investment_ids: [],
    reporter_ids: [],
  },
  sort: [{ field: 'daily_balance_date', type: 'desc' }],
}));
