var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-form', {
    attrs: {
      "i18nNamespace": "form.investments.fueling",
      "errors": _vm.feedback
    },
    model: {
      value: _vm.fueling,
      callback: function ($$v) {
        _vm.fueling = $$v;
      },
      expression: "fueling"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "investment_id",
      "integration": "investment_investment_relation"
    }
  }, [_c('investments-investment-picker', {
    model: {
      value: _vm.fueling.investment_id,
      callback: function ($$v) {
        _vm.$set(_vm.fueling, "investment_id", $$v);
      },
      expression: "fueling.investment_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "reporting_id"
    }
  }, [_c('core-user-picker', {
    model: {
      value: _vm.fueling.reporting_id,
      callback: function ($$v) {
        _vm.$set(_vm.fueling, "reporting_id", $$v);
      },
      expression: "fueling.reporting_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "order_date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "with-time": ""
    },
    model: {
      value: _vm.fueling.order_date,
      callback: function ($$v) {
        _vm.$set(_vm.fueling, "order_date", $$v);
      },
      expression: "fueling.order_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "crm_provider_id",
      "integration": "crm_address_relation"
    }
  }, [_c('crm-provider-picker', {
    model: {
      value: _vm.fueling.crm_provider_id,
      callback: function ($$v) {
        _vm.$set(_vm.fueling, "crm_provider_id", $$v);
      },
      expression: "fueling.crm_provider_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "amount_fuel"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "l"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.fueling.amount_fuel,
      callback: function ($$v) {
        _vm.$set(_vm.fueling, "amount_fuel", $$v);
      },
      expression: "fueling.amount_fuel"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "comment"
    }
  }, [_c('editor', {
    model: {
      value: _vm.fueling.comment,
      callback: function ($$v) {
        _vm.$set(_vm.fueling, "comment", $$v);
      },
      expression: "fueling.comment"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }