var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "machine-mth-report-modal"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-base-machines-mth-report-edit",
      "title": "Edytuj raport",
      "cancel-variant": "outline-secondary",
      "cancel-title": _vm.$t('base.machines.modal.reportMth.button.cancel'),
      "ok-title": _vm.$t('base.machines.modal.reportMth.button.save'),
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": function ($event) {
        $event.preventDefault();
        return _vm.updateReport.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var ok = _ref.ok;
        return [_c('vs-modal-footer', {
          attrs: {
            "is-loading": _vm.processing,
            "text-cancel": _vm.$t('base.machines.modal.reportMth.button.cancel'),
            "text-ok": _vm.$t('base.machines.modal.reportMth.button.save')
          },
          on: {
            "ok": ok,
            "cancel": function ($event) {
              _vm.mthReportModalEditVisible = false;
            }
          }
        })];
      }
    }]),
    model: {
      value: _vm.mthReportModalEditVisible,
      callback: function ($$v) {
        _vm.mthReportModalEditVisible = $$v;
      },
      expression: "mthReportModalEditVisible"
    }
  }, [_vm.processing ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Tworzenie raportu..."
    }
  }) : _vm._e(), _c('resource-report-form', {
    attrs: {
      "feedback": _vm.feedback,
      "refuel": _vm.form.has_fuel,
      "machineWhitelist": _vm.machineWhitelist,
      "employeeWhitelist": _vm.employeeWhitelist
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }