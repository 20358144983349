<template>
  <vs-form
    i18nNamespace="form.investments.fueling"
    v-model="fueling"
    :errors="feedback"
  >

    <vs-form-group field="investment_id" integration="investment_investment_relation">
      <investments-investment-picker
        v-model="fueling.investment_id"
      />
    </vs-form-group>

    <vs-form-group field="reporting_id">
      <core-user-picker
        v-model="fueling.reporting_id"
      />
    </vs-form-group>

    <vs-form-group field="order_date">
      <vs-datepicker with-time v-model="fueling.order_date" />
    </vs-form-group>

    <vs-form-group field="crm_provider_id" integration="crm_address_relation">
      <crm-provider-picker
        v-model="fueling.crm_provider_id"
      />
    </vs-form-group>

    <vs-form-group field="amount_fuel">
      <b-input-group append="l">
        <b-form-input v-model="fueling.amount_fuel" />
      </b-input-group>
    </vs-form-group>

    <vs-form-group field="comment">
      <editor
        v-model="fueling.comment"
      />
    </vs-form-group>

  </vs-form>
</template>

<script>
import { BInputGroup, BFormInput } from 'bootstrap-vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import { computed } from 'vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import CrmProviderPicker from '@/components/module/crm/CrmProviderPicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';
import InvestmentsInvestmentPicker from '@/components/module/investments/InvestmentsInvestmentPicker.vue';
import Editor from '@/components/ui/vs-wysiwyg/Editor.vue';

export default {
  name: 'InvestmentsFuelingForm',
  props: {
    value: Object,
    feedback: Object,
  },
  setup(props) {
    const fueling = computed(() => props.value);
    return { fueling };
  },
  components: {
    BInputGroup,
    BFormInput,

    VsForm,
    VsFormGroup,
    VsDatepicker,
    CrmProviderPicker,
    CoreUserPicker,
    InvestmentsInvestmentPicker,
    Editor,
  },
};
</script>
