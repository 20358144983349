<template>
  <div class="investments-report-form">

    <vs-loader
      v-if="isLoading"
      full
      text="Wczytywanie zawartości"
    />

    <vs-form
      i18nNamespace="investments.form.report.field"
      :errors="errors"
    >

      <vs-form-group
        field="investment_id"
      >
        <investments-investment-picker
          v-model="report.investment_id"
          :reduce="investmentReducer"
        />
      </vs-form-group>

      <vs-form-group
        field="management"
        class="management-list"
        >
        <!-- eslint-disable-next-line max-len -->
        <template v-if="selectedInvestmentManagement && selectedInvestmentManagement.length">
          <user-label isEmployee
            v-for="employee in selectedInvestmentManagement"
            :key="employee.id"
            :data="employee.cadre_employee"
            showLink
          >
            <template #extra-info>({{ employee.cadre_position.name }})</template>
          </user-label>
        </template>

        <div
          class="no-management-info"
          v-else-if="selectedInvestmentManagement && !selectedInvestmentManagement.length"
        >
          Brygada kierownicza wybranej inwestycji jest pusta
        </div>

        <div
          class="no-management-info"
          v-else-if="selectedInvestment.id && !selectedInvestmentManagement"
        >
          Wybrana inwestycja nie posiada brygady kierowniczej
        </div>

        <div class="no-management-info" v-else>
          Wybierz inwestycję, aby zobaczyć jej brygadę kierowniczą
        </div>
      </vs-form-group>

      <vs-form-group
        field="daily_balance_date"
        cols="9"
        labelCols="4"
        >
        <vs-datepicker
          placeholder="Wybierz datę raportu"
          v-model="report.daily_balance_date"
        />
      </vs-form-group>

      <vs-form-group
        field="report_time"
        no-label
        cols="3"
        >
        <b-form-timepicker
          :hour12="false"
          placeholder=""
          v-model="report.report_time"
        />
      </vs-form-group>

      <vs-form-group
        field="reporter_id"
        >
        <user-label withAvatar showLink />
      </vs-form-group>

      <vs-form-group
        field="work_time"
        :catches="['work_time_from', 'work_time_to']"
        >
        <div class="time-range">
          <b-form-timepicker
            class="time-range-picker"
            :hour12="false"
            placeholder=""
            v-model="report.work_time_from"
          />

          <div class="time-range-divider" />

          <b-form-timepicker
            class="time-range-picker"
            :hour12="false"
            placeholder=""
            v-model="report.work_time_to"
          />
        </div>
      </vs-form-group>

      <vs-form-group
        field="production_time"
        :catches="['production_time_from', 'production_time_to']"
        >
        <div class="time-range">
          <b-form-timepicker
            class="time-range-picker"
            :hour12="false"
            placeholder=""
            v-model="report.production_time_from"
          />

          <div class="time-range-divider" />

          <b-form-timepicker
            class="time-range-picker"
            :hour12="false"
            placeholder=""
            v-model="report.production_time_to"
          />
        </div>
      </vs-form-group>

      <vs-form-group
        field="investment_brigade_id"
        info="Wybór spośród brygad wybranej inwestycji, posiadających przypisanie do maszyny"
      >
        <investments-brigade-picker
          withMainVehicleOnly
          autoselect
          v-model="report.investment_brigade_id"
          :investment-id="report.investment_id"
          v-if="selectedInvestment && selectedInvestment.id"
          @pick="onBrigadePick"
        />

        <div class="no-management-info" v-else>
          Przed wybraniem brygady musisz wybrać inwestycję
        </div>

        <template
          v-if="selectedBrigade && selectedBrigade.employees"
        >

          <vs-employee-multiselect
            :employees="sortedEmployees"
            @add:employee="addReportedEmployee"
            @delete:employee="deleteReportedEmployee"
            @update:position="updateReportedEmployeePosition"
          >

            <template #employee-subtitle="{ employee }">
              <div
                class="brigade-employee-info"
                v-if="employeeInSelectedBrigade(employee.cadre_employee_id)"
              >
                Z wybranej brygady
              </div>
              <div
                class="brigade-employee-info"
                v-if="employee.brigadeName"
              >
                {{ employee.brigadeName }}
              </div>
            </template>

          </vs-employee-multiselect>
        </template>
      </vs-form-group>

      <h4>Przerób dzienny</h4>

      <b-alert
        variant="danger"
        class="no-requirements-info"
        v-if="selectedInvestment && !selectedInvestment?.requirements?.length && rows.length"
        show
      >
        W wybranej inwestycji nie uzupełniono zapotrzebowania.
        Dopóki ich nie uzupełnisz w
        <router-link
          :to="{ name: 'investments.details.settings', params: { id: selectedInvestment.id } }"
        >
          ustawieniach tej inwestycji</router-link>,
        utworzenie raportu nie będzie możliwe.
      </b-alert>

      <div class="vs-table-wrapper" v-if="rows.length">
        <vs-table
          :columns="columns"
          :rows="rows"
          :show-search="false"
          :show-pagination="false"
        >
          <template v-slot:row="{ row, column }" >

            <span v-if="column.field === 'technology'">
              {{ row.technology.name }}
              <div class="technology-description">
                {{ row.technology.description }}
              </div>
            </span>

            <span v-else-if="column.editableField" class="editable-cell">
              <b-form-input
                v-model="getEditable(row.subject_id)[column.editableField]"
                :state="isEditableValid(getEditable(row.subject_id)[column.editableField])"
                placeholder="0"
              />
            </span>

            <span v-else-if="column.isRequirementGroup" class="requirement-group-cell">
              <b-input-group :append="column.suffix">
                <b-form-input
                  v-model="getEditableRequirement(row.subject_id, column.id).quantity"
                  :state="isEditableValid(
                    getEditableRequirement(row.subject_id, column.id).quantity
                  )"
                  placeholder="0"
                />
              </b-input-group>
            </span>

          </template>
        </vs-table>
      </div>

      <b-alert
        show
        variant="danger"
        class="no-requirements-info"
        v-else-if="selectedInvestment?.id"
      >
        Wybrana inwestycja nie posiada żadnych przedmiotów zamówienia.
        Dopóki ich nie uzupełnisz w
        <router-link
          :to="{ name: 'investments.details.settings', params: { id: selectedInvestment.id } }"
        >
          ustawieniach tej inwestycji</router-link>,
        utworzenie raportu nie będzie możliwe.
      </b-alert>

      <div style="width: 100%">
        <h4>Uwagi</h4>
        <b-form-textarea v-model="report.description" />
      </div>

    </vs-form>
  </div>
</template>

<script>
import {
  BAlert,
  BFormTimepicker,
  BInputGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import i18n from '@/libs/i18n';
import { computed, ref, watch } from 'vue';
import InvestmentsBrigadePicker from '@/components/module/investments/InvestmentsBrigadePicker.vue';
import InvestmentsInvestmentPicker from '@/components/module/investments/InvestmentsInvestmentPicker.vue';
import store from '@/store';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsEmployeeMultiselect from '@/components/ui/vs-employee-multiselect/VsEmployeeMultiselect.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';

export default {
  name: 'InvestmentsReportForm',
  emits: ['usageUpdate', 'investmentUpdate'],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const tableI18n = 'investments.form.report.work.table';
    const report = computed(() => props.value);
    const investmentBrigades = computed(() => store
      .getters['investments/brigades/byInvestmentId'](report?.value?.investment_id));

    const brigadesWithoutMachine = computed(() => investmentBrigades.value
      .filter((b) => b.investment_base_vehicle_id === null));
    const machinelessBrigadesEmployees = computed(() => brigadesWithoutMachine.value
      .reduce((acc, b) => [
        ...acc,
        ...b.employees.map((e) => ({ ...e, brigadeName: b.name })),
      ], []));

    const errors = ref({});
    watch(() => props.feedback, () => { errors.value = props.feedback; });

    store.dispatch('units/fetch');
    const getUnit = store.getters['units/byId'];

    const reportedEmployees = ref([]);

    const selectedBrigade = ref({});
    const onBrigadePick = (brigade) => {
      if (report.value?.investment_brigade_id
        && brigade.id === report.value?.investment_brigade_id) {
        selectedBrigade.value = brigade;

        const newEmployees = [
          ...brigade.employees,
          ...machinelessBrigadesEmployees.value,
        ];
        reportedEmployees.value = newEmployees
          .filter((e) => !e.cadre_employee?.deleted_at);
      }
      return brigade.id;
    };
    const employeeInSelectedBrigade = (employeeId) => selectedBrigade.value.employees
      .find((r) => r.cadre_employee_id === employeeId);
    const employeeInMachinelessBrigades = (employeeId) => machinelessBrigadesEmployees
      .value.find((r) => r.cadre_employee_id === employeeId);

    const addReportedEmployee = (addedEmployee) => {
      const employee = employeeInSelectedBrigade(addedEmployee.id)
        ?? employeeInMachinelessBrigades(addedEmployee.id)
        ?? {
          cadre_employee_id: addedEmployee.id,
          cadre_employee: addedEmployee,
          cadre_position_id: 1,
        };

      reportedEmployees.value.push(employee);
    };

    const deleteReportedEmployee = (employee) => {
      reportedEmployees.value = reportedEmployees.value
        .filter((e) => e.cadre_employee.id !== employee.cadre_employee.id);
    };

    const updateReportedEmployeePosition = (employeeId, positionId) => {
      const employee = reportedEmployees.value.find((r) => r.cadre_employee_id === employeeId);
      if (!employee) return;
      employee.cadre_position_id = positionId;
    };

    const sortedEmployees = computed(() => [...reportedEmployees.value].sort((a, b) => {
      if (employeeInSelectedBrigade(b.cadre_employee_id)) return 1;
      if (employeeInSelectedBrigade(a.cadre_employee_id)) return -1;
      if (employeeInMachinelessBrigades(b.cadre_employee_id)) return 1;
      if (employeeInMachinelessBrigades(a.cadre_employee_id)) return -1;
      return -1;
    }));

    watch(() => reportedEmployees.value, (val) => {
      report.value.assigned_employees = val.map((employee) => ({
        cadre_employee_id: employee.cadre_employee_id,
        cadre_position_id: employee.cadre_position_id,
      }));
    }, { deep: true });

    const selectedInvestment = ref({});
    const investmentReducer = (investment) => {
      if (investment.id === report.value.investment_id && report.value.investment_id) {
        selectedInvestment.value = investment;
        emit('investmentUpdate', investment);
      }
      return investment.id;
    };

    const selectedInvestmentManagement = computed(() => {
      if (!selectedInvestment.value?.management_brigade) return null;
      return selectedInvestment.value.management_brigade?.employees ?? [];
    });

    const rows = computed(() => {
      const investmentSubjects = selectedInvestment.value?.subjects ?? [];
      if (investmentSubjects.length === 0) return [];

      return investmentSubjects.map((subject) => ({
        subject_id: subject.id,
        technology: {
          name: subject.technology.name,
          description: `(${subject.quantity} x ${Math.floor(subject.length)}m)`,
        },
        diameter: `${Math.floor(subject.diameter)}mm`,
      }));
    });

    const columns = computed(() => {
      const investmentDemands = selectedInvestment.value?.requirements ?? [];

      const demandColumns = investmentDemands
        .reduce((unique, d) => {
          if (!unique.some((ud) => ud.requirement_group.id === d.requirement_group.id)) {
            unique.push(d);
          }
          return unique;
        }, [])
        .map((demand) => ({
          label: demand.requirement_group.name,
          suffix: getUnit(demand.requirement_group.core_unit_id)?.code,
          isRequirementGroup: true,
          id: demand.requirement_group.id,
          field: 'material',
        }));

      return [
        {
          label: i18n.t(`${tableI18n}.technology`),
          field: 'technology',
        },
        {
          label: i18n.t(`${tableI18n}.diameter`),
          field: 'diameter',
        },
        {
          label: i18n.t(`${tableI18n}.quantity`),
          field: 'quantity',
          editableField: 'quantity',
        },
        {
          label: i18n.t(`${tableI18n}.mb`),
          field: 'mb',
          editableField: 'current_meter',
        },
        {
          label: i18n.t(`${tableI18n}.project_mb`),
          field: 'project_mb',
          editableField: 'project_current_meter',
        },
        ...demandColumns,
      ];
    });

    const sampleEditable = ref([]);

    watch(rows, () => {
      const investmentDemands = selectedInvestment.value?.requirements ?? [];

      sampleEditable.value = rows.value
        .map((row) => ({
          investment_subject_id: row.subject_id,
          quantity: null,
          current_meter: null,
          project_current_meter: null,
          daily_balance_usage: investmentDemands
            .reduce((unique, d) => {
              if (!unique.some((ud) => ud.requirement_group.id === d.requirement_group.id)) {
                unique.push(d);
              }
              return unique;
            }, [])
            .map((d) => ({
              investment_requirement_group_id: d.requirement_group.id,
              quantity: null,
            })),
        }));
    });

    const getEditable = (subjectId) => sampleEditable.value
      .find((e) => e.investment_subject_id === subjectId) ?? {};

    const getEditableRequirement = (subjectId, requirementId) => getEditable(subjectId)
      ?.daily_balance_usage
      ?.find((d) => d.investment_requirement_group_id === requirementId) ?? {};

    watch(sampleEditable, () => {
      emit('usageUpdate', sampleEditable.value);
    }, { deep: true });

    const isLoading = ref(false);

    const isEditableValid = (val) => {
      if (!val) return null;
      return !Number.isNaN(Number(val.replace(',', '.')));
    };

    return {
      errors,
      columns,
      rows,
      report,
      selectedInvestment,
      selectedInvestmentManagement,
      selectedBrigade,
      investmentReducer,
      onBrigadePick,
      sampleEditable,
      isLoading,
      isEditableValid,

      reportedEmployees,
      sortedEmployees,
      addReportedEmployee,
      deleteReportedEmployee,
      updateReportedEmployeePosition,
      employeeInSelectedBrigade,

      getEditable,
      getEditableRequirement,
    };
  },
  components: {
    BAlert,
    BFormTimepicker,
    BInputGroup,
    BFormInput,
    BFormTextarea,

    VsForm,
    VsFormGroup,
    VsTable,
    UserLabel,
    InvestmentsBrigadePicker,
    InvestmentsInvestmentPicker,
    VsLoader,
    VsEmployeeMultiselect,
    VsDatepicker,
  },
};
</script>

<style lang="sass" scoped>
.brigade-employee-info
  border-radius: 5px

.selected-brigade-employee-list
  margin-top: 10px

  .user-label::v-deep
    display: inline

    .extra-info
      margin-left: 0 !important

    &:not(:last-child):after
      content: ', '
      opacity: 0.5

.vs-table-wrapper
  width: 100%
  overflow-x: auto

  .editable-cell
    min-width: 60px
    display: block

  .requirement-group-cell
    min-width: 120px
    display: block

.technology-description
  opacity: 0.5

.management-list
  .user-label
    margin: 5px 0

.no-management-info
  opacity: 0.4
  display: flex
  align-items: center
  height: 100%
  pointer-events: none

.no-requirements-info
  padding: 10px

h4
  margin: 30px 0 15px 0

.time-range
  display: flex
  align-items: center

  &-picker
    max-width: 105px

  &-divider
    width: 20px
    height: 2px
    margin: 0 15px
    background: rgba(128, 128, 128, 0.3)
</style>
