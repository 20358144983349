<template>
  <div class="investments-report-form-mth">
    <investments-report-form-details
      :investment="investment"
      :reportDate="reportDate"
    />

    <vs-table
      class="owned-list"
      :columns="resourceColumns"
      :rows="ownedResources"
      :show-search="false"
      :show-pagination="false"
      :showActionButton="false"
      :sortOptions="{ enabled: false }"
      :isLoading="isLoading"
    >
      <template v-slot:row="{ column, row }" >

        <span
          v-if="column.field === 'meter_status'"
          class="editable-cell"
          >
          <b-input-group :append="unitStore.getShortcode(row.unit_id)">
            <b-form-input
              :value="row.meter_status"
              :placeholder="row.previousMeterStatus"
              :state="isMeterStatusValid(row)"
              @input="(val) => updateResource(row, 'meter_status', val)"
            />
          </b-input-group>
          <b-form-invalid-feedback :state="isMeterStatusValid(row)">
            {{ getMeterStatusValidationMessage(row) }}
          </b-form-invalid-feedback>
        </span>

        <div v-else-if="column.field === 'name'" >
          <base-resource-label
            :id="row.vehicle_id"
            :resource="row"
            target="_blank"
          />
        </div>

        <span
          v-else-if="column.field === 'operator_id'"
          class="editable-cell"
          >
          <cadre-employee-picker
            :whitelist="investmentEmployeeIds"
            :value="row.operator_id"
            @input="(val) => updateResource(row, 'operator_id', val)"
          >
            <template #selectedProfession="{ id }">
              {{ getEmployeeBrigadePosition(id) }}
            </template>
            <template #listedProfession="{ id }">
              {{ getEmployeeBrigadePosition(id) }}
            </template>
          </cadre-employee-picker>
        </span>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { BFormInput, BInputGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import { computed, ref } from 'vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import showToast from '@/libs/toasts';
import { FetchInvestmentError } from '@/libs/toasts/feedback/module/investments';
import useBaseApi from '@/hooks/base/useBaseApi';
import BaseResourceLabel from '@/components/views/base/resource/BaseResourceLabel.vue';
import useCoreUnitStore from '@/stores/module/core/useCoreUnitStore';
import InvestmentsReportFormDetails from './InvestmentsReportFormDetails.vue';

export default {
  name: 'InvestmentsReportFormMth',
  emits: ['update:investment', 'update:reports'],
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
    reportDate: {
      type: String,
      default: '-',
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(true);
    const unitStore = useCoreUnitStore();

    const investmentEmployees = computed(() => {
      if (!props?.investment?.brigades) return [];
      const brigades = props.investment.brigades ?? [];

      return brigades
        .reduce((employees, brigade) => [...employees, ...brigade.employees], []);
    });

    const investmentEmployeeIds = computed(() => investmentEmployees.value
      .map((e) => e.cadre_employee_id));

    const getEmployeeBrigadePosition = (employeeId) => {
      const employee = investmentEmployees.value
        .find((e) => e.cadre_employee_id === employeeId);

      if (!employee) return null;

      return employee?.cadre_position?.name;
    };

    const resourceColumns = [
      { label: 'Nazwa', field: 'name' },
      { label: 'Grupa', field: 'group' },
      { label: 'Operator', field: 'operator_id', editable: true },
      { label: 'MTH', field: 'meter_status', editable: true },
    ];

    const ownedResources = ref([]);

    const updateMachineMeterStatuses = () => {
      isLoading.value = true;
      const vehicleIds = ownedResources.value.map((r) => r.resource_id);

      useBaseApi()
        .fetchResourceSummaries(vehicleIds)
        .then(({ data }) => {
          isLoading.value = false;
          const fetchedSummaries = data?.data ?? [];

          ownedResources.value = ownedResources.value.map((resource) => {
            const resourceSummary = fetchedSummaries
              .find((summary) => summary.id === resource.resource_id);

            if (!resourceSummary) return resource;

            return {
              ...resource,
              previousMeterStatus: resourceSummary.meter_status,
            };
          });
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    // get more detailed investment data
    useInvestmentsApi()
      .fetchInvestment(props.investment.id)
      .then(({ data }) => {
        isLoading.value = false;
        emit('update:investment', data.data);
        const vehicles = data.data?.base_resource ?? [];

        ownedResources.value = vehicles
          .filter(({ resource }) => resource.category?.be_reported)
          .filter(({ resource }) => ['tool', 'vehicle'].includes(resource.type.slug))
          .reduce((acc, current) => {
            // remove duplicated vehicles
            if (acc.find((a) => a.id === current.id)) return acc;
            return [...acc, current];
          }, [])
          .map((v) => ({
            resource_id: v.base_resource_id,
            vehicle_id: v.base_resource_id,
            name: v.resource?.name,
            group: v.resource?.last_activity?.group?.name ?? 'n/d',
            operator_id: null,
            meter_status: null,
            unit_id: v.resource?.category?.unit_id,
            previousMeterStatus: v.resource?.meter_status?.status ?? '0',
          }))
          .sort((a, b) => ((a.group - b.group) ? 1 : -1));

        updateMachineMeterStatuses();
      })
      .catch(() => {
        isLoading.value = false;
        showToast(FetchInvestmentError);
      });

    const isMeterStatusValid = (row) => {
      if (!row.meter_status) return null;
      return Number(row.meter_status) >= Number(row.previousMeterStatus);
    };

    const getMeterStatusValidationMessage = (row) => {
      if (isMeterStatusValid(row) !== false) return null;
      return `Uwaga: podajesz mniejszą wartość niż aktualny stan licznika (${row.previousMeterStatus} mth)`;
    };

    const updateResource = (row, field, value) => {
      const resource = ownedResources.value
        .find((res) => res.resource_id === row.resource_id);

      if (!(field in resource)) return;

      resource[field] = value;

      emit('update:reports', ownedResources.value
        .filter((filtered) => {
          if (!filtered.meter_status) return false;
          return filtered.previousMeterStatus !== filtered.meter_status;
        }));
    };

    return {
      resourceColumns,
      ownedResources,
      investmentEmployeeIds,
      getEmployeeBrigadePosition,
      isMeterStatusValid,
      updateResource,
      getMeterStatusValidationMessage,
      isLoading,
      unitStore,
    };
  },
  components: {
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    InvestmentsReportFormDetails,
    VsTable,
    CadreEmployeePicker,
    BaseResourceLabel,
  },
};
</script>

<style lang="sass" scoped>
.investments-report-form-mth
  padding: 30px

.invalid-meter-status
  color: red

.vs-table::v-deep
  margin-top: 25px
  max-width: 100%

  .editable-cell
    display: block
    min-width: 230px
</style>
