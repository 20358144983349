import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const investmentsFuelingColumns = [
  {
    label: i18n.t('investments.fueling.field.date'),
    field: 'date',
  },
  {
    label: i18n.t('investments.fueling.field.investment'),
    field: 'investment',
  },
  {
    label: i18n.t('investments.fueling.field.amount'),
    field: 'amount',
  },
  {
    label: i18n.t('investments.fueling.field.provider'),
    field: 'provider',
  },
  {
    label: i18n.t('investments.fueling.field.reporter'),
    field: 'reporter',
  },
];

export default ref(createTable('investments/fueling', investmentsFuelingColumns, {
  filters: {
    provider_id: [],
    reporting_id: [],
    investment_id: [],
    date_from: null,
    date_to: null,
  },
  sortable: ['date'],
}));
